import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            VRow,
            {
              staticClass: "pa-4",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "25", color: "primary" },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              !_vm.bc
                ? _c("h1", { staticClass: "text-center mt-6" }, [
                    _vm._v(" " + _vm._s(_vm.$t("no_bc")) + " "),
                  ])
                : _c(
                    "div",
                    { staticClass: "my-4" },
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "12", sm: "12", md: "4" } },
                            [
                              _c("h3", [_vm._v(_vm._s(_vm.$t("status")))]),
                              _c("h2", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.bc.Status ==
                                        _vm.bc_status_enum.Processing
                                        ? _vm.$t("processing")
                                        : _vm.bc.Status ==
                                          _vm.bc_status_enum.Analysing
                                        ? _vm.$t("under_analysis")
                                        : _vm.$t("concluded")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "12", sm: "12", md: "4" } },
                            [
                              _c("h3", [_vm._v(_vm._s(_vm.$t("last_update")))]),
                              _c("h2", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.gs.convert_date(
                                        _vm.bc.LastUpdate,
                                        true
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "12", sm: "12", md: "4" } },
                            [
                              _c("h3", [_vm._v(_vm._s(_vm.$t("result")))]),
                              _c("h2", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.bc.Result ==
                                        _vm.bc_result_enum.NOT_REVIEWED
                                        ? _vm.$t("not_reviewed")
                                        : _vm.bc.Result ==
                                          _vm.bc_result_enum.REVIEW
                                        ? _vm.$t("review")
                                        : _vm.bc.Result ==
                                          _vm.bc_result_enum.INVALID
                                        ? _vm.$t("invalid")
                                        : _vm.$t("valid")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                VRow,
                { staticClass: "my-2", attrs: { justify: "center" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: {
                        loading: _vm.requestLoading,
                        rounded: "",
                        dense: "",
                        color: "primary",
                      },
                      on: { click: _vm.request_bc },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("request_bc")) + " ")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        loading: _vm.updateLoading,
                        rounded: "",
                        dense: "",
                        color: "primary",
                      },
                      on: { click: _vm.bc_status_update },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("bc_update")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }